<template>
  <div class="ocr">
    <div class="box">
      <div class="left leftFlex">
        <IdiomSubNav>
          <div
            class="history"
            id="ocrCom"
            @click="ocr"
            style="margin-left: 20px"
          >
            <img class="im" :src="require('assets/datehot.png')" alt="" />
            <span class="txt">截图OCR</span>
          </div>
          <!-- <div class="history" id="CAJocrUpload" @click="unData">
        <img :src="require('assets/images/CAJ-ocr.png')" alt="" />
        <span class="txt">CAJ转文字</span>
      </div> -->
          <div class="history" id="IMGocrUpload" @click="unData">
            <img :src="require('assets/images/img-ocr.png')" alt="" />
            <span class="txt">图片转文本</span>
          </div>
          <!-- <div class="history" id="PDFocrUpload" @click="unData">
        <img :src="require('assets/images/pdf-ocr.png')" alt="" />
        <span class="txt">PDF转文字</span> 
      </div> -->
          <div class="history" id="PPTocrUpload" @click="unData">
            <img :src="require('assets/images/ppt-ocr.png')" alt="" />
            <span class="txt">PPT转文本</span>
          </div>
          <div class="history" id="CAJocrUpload" @click="unData">
            <img :src="require('assets/images/CAJ-ocr.png')" alt="" />
            <span class="txt">CAJ转Word</span>
          </div>
          <div class="history" id="PDFocrUpload" @click="unData">
            <img :src="require('assets/images/pdf-ocr.png')" alt="" />
            <span class="txt">PDF转Word</span>
          </div>
          <div class="history" id="PDFocrUpload" @click="showOcr">
            <img class="im" :src="require('assets/images/record.png')" alt="" />
            <span class="txt">OCR记录</span>
          </div>
          <!-- <div class="history" id="insetLeft" @click="insertLeftText">
        <img class="im" :src="require('assets/images/goldenSentence/add.png')" alt="" />
        <span class="txt">插入左侧</span>
      </div>
      <div class="history" @click="copy">
        <img class="im" :src="require('assets/images/goldenSentence/subTitle-icon.png')" alt="" />
        <span class="txt" id="copy">复制</span>
      </div> -->
        </IdiomSubNav>
        <div class="progress" :style="{ width: progress + '%' }"></div>
        <div class="noticeHot" v-if="isShowRecord">
          <div class="list-content-item-up">
            <!-- <i
              class="list-content-item-add"
              id="insetLeft"
              @click="insertLeftText"
            ></i> -->
            <i class="list-content-collection" id="copy" @click="copy"></i>
            <el-input
              type="textarea"
              placeholder="OCR内容"
              v-model="ocrText"
              :rows="30"
              show-word-limit
            ></el-input>
            <!-- <i class="list-content-item-add" @click="insertLeftText"></i>
        <div class="list-content-title">
          <span>{{ ocrText }}</span> -->
            <!-- <i class="list-content-collection" @click="copy"></i> -->
            <!-- </div> -->
          </div>
          <div class="mask" v-if="isShowMask">
            <div class="btn">{{ tipsText }}</div>
          </div>
        </div>
        <div class="recordList" v-else>
          <record @getChildContext="getChildContext"></record>
        </div>
        <el-dialog
          :visible.sync="showRealNameTips"
          :modal="false"
          :close-on-click-modal="false"
          title="提示"
          class="ocr-name-dialog"
        >
          <div class="content">{{ text }}文件转Word文档成功</div>
          <div class="footer">
            <!-- <span class="now" @click="realNameDialog">预览</span> -->
            <span class="next" @click="showRealName">下载</span>
          </div>
        </el-dialog>
        <input id="upload" type="file" ref="file" @change="doRealUpload" />
      </div>
      <div class="right recommendRightList">
        <recommend />
      </div>
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import commonFooter from "components/footer/commonFooter.vue";
import recommend from "components/recommend/recommend.vue";
import IdiomSubNav from "components/idiomsub-nav/IdiomSubNav";
import { hotList } from "api/disk";
import record from "./record";
import { ocrUploadImage, ocrPdfToWord, findFileIsComplete } from "api/ocr";
import defineSteps from "./driver/driver.js";
import { isFirstLogin } from "api/checking";
import { isHasFreeCount } from "api/common";
import { previewUrl } from "api/common";
export default {
  data() {
    return {
      isShowMask: false,
      // 弹框文字
      text: "",
      // 提示弹框
      showRealNameTips: false,
      showLook: true,
      // title: "OCR内容",
      ocrText: "",
      // ocrText1:"",
      list: [],
      wordUrl: "",
      fileType: [],
      // 判断是否Caj或者pdf
      ocrTOcaj: false,
      tipsText: "",
      isShowRecord: true,
      timer: null,
      currentFileId: "",
      progress: 0,
      progressTimer: null,
      isPlaying: 0,
    };
  },
  created() {
    this.$bus.$on("ocrTexts", (val) => {
      this.ocrText = val;
    });
  },
  beforeDestroy() {
    clearInterval(this.progressTimer);
    clearInterval(this.timer);
  },
  methods: {
    // 下载
    async showRealName() {
      console.log(this.wordUrl);
      // window.ShowDownLoadTips();
      const res = await isHasFreeCount({
        menuName: "截图OCR",
        ocrRecordId: this.currentFileId,
      });
      if (res.code == 415) {
        this.$store.commit("setBuyTipsInfo", res.data);
        this.$bus.$emit("buyTips");
        return;
      }
      try {
        console.log(this.wordUrl);
        let fileName = this.wordUrl.fileName;
        let url = this.wordUrl.parseUrl;
        const suffix = this.wordUrl.parseUrl.substring(
          this.wordUrl.parseUrl.lastIndexOf(".")
        );
        // eslint-disable-next-line
        window.open(url + "?attname=" + fileName + suffix);
      } catch (e) {
        console.log(e);
      }
    },

    getChildContext(item) {
      this.isShowRecord = true;
      this.ocrText = item;
    },
    // 预览
    async realNameDialog() {
      window.ShowDownLoadTips();
      // const res = await isHasFreeCount({
      //   menuName: "截图OCR",
      //   ocrRecordId: this.currentFileId,
      // });
      // if (res.code == 415) {
      //   this.$store.commit("setBuyTipsInfo", res.data);
      //   this.$bus.$emit("buyTips");
      //   return;
      // }
      // try {
      //   let fileName = this.wordUrl.fileName;
      //   let url = this.wordUrl.url;
      //   // eslint-disable-next-line
      //   OpenDocument2(fileName, url);
      //   if (res.message.indexOf("校验") == -1) {
      //     this.$message({
      //       type: "success",
      //       message: res.message,
      //       customClass: "short-message",
      //       duration: 2000,
      //     });
      //   }
      // } catch (e) {
      //   console.log(e);
      // }
    },
    //是否显示引导
    async isShowDriver() {
      const data = {
        // userId:this.$store.state.user_info.id,
        pageName: "插件版OCR",
      };
      // console.log(data);
      const res = await isFirstLogin(data);
      // console.log(res.data);
      if (res.data) {
        this.$driver.defineSteps(defineSteps);
        this.$driver.start();
      }
    },

    // 上传文件
    unData(e) {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }

      this.isShowRecord = true;
      switch (e.target.innerText) {
        case "CAJ转文字":
          this.ocrTOcaj = false;
          this.fileType = ["CAJ", "caj"];
          break;
        case "CAJ转Word":
          this.text = "CAJ";
          this.ocrTOcaj = true;
          this.fileType = ["CAJ", "caj"];
          break;
        case "图片转文本":
          this.ocrTOcaj = false;
          this.fileType = [
            "gif",
            "jpg",
            "jpeg",
            "png",
            "GIF",
            "JPG",
            "JEPG",
            "PNG",
          ];
          break;
        case "PDF转文本":
          this.ocrTOcaj = false;
          this.fileType = ["PDF", "pdf"];
          break;
        case "PPT转文本":
          this.ocrTOcaj = false;
          this.fileType = ["PPT", "PPTX", "pptx", "ppt"];
          break;
        case "PDF转Word":
          this.text = "PDF";
          this.ocrTOcaj = true;
          this.fileType = ["PDF", "pdf"];
          break;
      }
      this.$refs.file.click();
    },
    showOcr() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      this.isShowRecord = false;
    },
    async doRealUpload(e) {
      let file = e.target.files[0];
      console.log(file);
      if (!file) {
        return;
      }
      if (this.isPlaying != 0) {
        return this.$message.error("当前有文件在识别,请等待当前文件识别完成");
      }
      this.progress = 0;

      // console.log(file);
      const index = file.name.lastIndexOf(".");
      let type = file.name.substr(index + 1);
      if (this.fileType.indexOf(type) == -1) {
        return this.$message({
          type: "error",
          message: `上传文件必须是${this.fileType.join()}类型`,
        });
      }
      this.progressTimer = setInterval(() => {
        if (this.progress > 80) {
          clearInterval(this.progressTimer);
        }
        let num = Math.floor(Math.random() * (15 - 10 + 1) + 5);
        this.progress += num;
      }, 2000);
      let form = new FormData();

      form.append("file", file);
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      if (this.ocrTOcaj) {
        try {
          const res = await ocrPdfToWord(form);
          if (res.code === 200) {
            this.currentFileId = res.data;
            loading.close();
            this.timer = setInterval(async () => {
              this.isPlaying++;
              if (this.isPlaying > 20) {
                this.isPlaying = 0;
                this.progress = 0;
                this.$alert(
                  "当前文件转换时间较长，请稍后前往ocr记录查看",
                  "提示",
                  {
                    confirmButtonText: "确定",
                    callback: (action) => {},
                  }
                );
                clearInterval(this.timer);
              }
              const isComplete = await findFileIsComplete(res.data);
              // console.log(isComplete);
              if (isComplete.data.status == 1 || isComplete.data.status == 2) {
                if (isComplete.data.data.type == 2) {
                  console.log(isComplete);
                  clearInterval(this.progressTimer);
                  this.progress = 100;

                  setTimeout(() => {
                    this.progress = 0;
                  }, 5000);
                  this.wordUrl = isComplete.data.data;
                  this.$nextTick(() => {
                    this.showRealNameTips = true;
                  });
                }
                clearInterval(this.timer);
                this.isPlaying = 0;
              }
            }, 5000);
          } else {
            loading.close();
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        } catch (e) {
          loading.close();
          this.$message.error("转换失败");
          this.progress = 0;
          this.isPlaying = 0;
        }
      } else {
        try {
          const res = await ocrUploadImage(form);
          if (res.code === 200) {
            loading.close();
            // this.ocrText = res.data;

            this.timer = setInterval(async () => {
              this.isPlaying++;
              if (this.isPlaying > 20) {
                this.isPlaying = 0;
                this.progress = 0;
                this.$alert(
                  "当前文件转换时间较长，请稍后前往ocr记录查看",
                  "提示",
                  {
                    confirmButtonText: "确定",
                    callback: (action) => {},
                  }
                );
                clearInterval(this.timer);
              }
              const isComplete = await findFileIsComplete(res.data);
              console.log(isComplete);
              if (isComplete.data.status == 1 || isComplete.data.status == 2) {
                if (isComplete.data.data.type == 1) {
                  clearInterval(this.progressTimer);
                  this.progress = 100;
                  setTimeout(() => {
                    this.progress = 0;
                  }, 5000);
                  this.ocrText = isComplete.data.data.content;
                }
                clearInterval(this.timer);
                this.isPlaying = 0;
                const result = await isHasFreeCount({
                  menuName: "截图OCR",
                  ocrRecordId: res.data,
                });
                if (result.code == 415) {
                  this.isShowMask = true;
                  this.$store.commit("setBuyTipsInfo", result.data);
                  this.$bus.$emit("buyTips");
                  return;
                }
                if (result.data.title.indexOf("校验") == -1) {
                  this.$message({
                    type: "success",
                    message: result.message,
                    customClass: "short-message",
                    duration: 2000,
                  });
                }
              }
            }, 2000);
          } else {
            loading.close();
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        } catch (e) {
          loading.close();
          this.$message.error("转换失败");
          this.progress = 0;
          this.isPlaying = 0;
        }
      }
    },
    // 数据
    async hotList(data) {
      let res = await hotList(data);
      this.list = res.data;
    },
    //插入
    async insertLeftText() {
      window.ShowDownLoadTips();
    },
    //复制内容
    async copy() {
      let value = this.ocrText;
      //执行复制
      let domUrl = document.createElement("input");
      domUrl.value = value;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select();
      document.execCommand("Copy");
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        type: "success",
        message: "复制成功！",
        customClass: "short-message",
        duration: 300,
      });
    },
    //截图OCR
    ocr() {
      window.ShowDownLoadTips();
    },
    //内容溯源选项改变
    notesChange() {
      this.title = this.$refs["idiomEditor"].value3 + "年度热榜";
      let y = this.$refs["idiomEditor"].value3;
      let form1 = new FormData();
      form1.append("year", y);
      this.hotList(form1);
    },
    //内容溯源选项改变
    notesChange1() {
      this.title = this.$refs["notesChange2"].value3 + "月度热榜";
      let m = this.$refs["notesChange2"].value3;
      let form2 = new FormData();
      form2.append("month", m);
      this.hotList(form2);
    },
    // 截图OCR
    locationDatehot() {
      this.title = "截图OCR";
      var date = new Date();
      let d = date.getDate();

      let form = new FormData();
      form.append("day", d);
      this.hotList(form);
    },
    // 查看更多
    clicklook() {
      this.showLook = false;
    },
  },

  components: {
    IdiomSubNav,
    record,
    recommend,
    commonFooter,
    // DropDownList,
    // SelectNotes,
    // SelectNotes1
  },
  mounted() {
    //this.$refs['droplist'].getLibGroup(1);
    window.receiveOcrText = async (content) => {
      //alert(decodeURI(content) );
      this.ocrText = content;
      const res = await isHasFreeCount({ menuName: "截图OCR" });
      if (res.code == 415) {
        this.isShowMask = true;
        // this.$store.commit("setBuyTipsInfo", res.data);
        // this.$bus.$emit("buyTips");
        return;
      }

      if (res.data.title.indexOf("校验") == -1) {
        this.$message({
          type: "success",
          message: res.message,
          customClass: "short-message",
          duration: 2000,
        });
      }
    };
    // this.$driver.defineSteps(defineSteps);
    // this.$driver.start();
    this.isShowDriver();
  },
};
</script>

<style lang="scss" scoped>
.list-content-item-up {
  position: relative;
  display: flex;
  line-height: 26px;
  padding-top: 16px;
  text-align: left;
  .list-content-item-add {
    width: 18px;
    height: 18px;
    @include backgroundGroup("../../assets/images/goldenSentence/add.png");
    margin-right: 11px;
    cursor: pointer;
    transform: translateY(4px);
  }
  .list-content-collection {
    position: absolute;
    top: 60px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    @include backgroundGroup(
      "../../assets/images/goldenSentence/subTitle-icon.png"
    );
    transform: translateY(2px);
  }
  .list-content-title {
    width: 99%;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    cursor: pointer;
    .list-content-collection {
      position: absolute;
      top: 0;
      width: 17px;
      height: 16px;
      cursor: pointer;
      @include backgroundGroup(
        "../../assets/images/goldenSentence/subTitle-icon.png"
      );
      transform: translateY(2px);
    }
    .collection {
      @include backgroundGroup(
        "../../assets/images/goldenSentence/subTitle-icon.png"
      );
    }
  }
  .list-content-form {
    margin-left: 35px;
    width: 90px;
    padding: 0 10px;
    color: #ffffff;
    background: #4587ff;
    border-radius: 50px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    text-align: center;
    @include ellipsis();
  }
}
.noticeHot {
  width: 100%;

  padding: 18px 34px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($color: #999, $alpha: 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-textarea {
    &::placeholder {
      color: red;
    }
  }
  h1 {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #262e3e;
    margin: 18px 0;
  }
  .noticeHot_banner {
    display: flex;

    .index {
      width: 35px;
      height: 17px;
      font-size: 12px;
      color: #ffffff;
      line-height: 17px;
      margin-top: 10px;
      margin-right: 27px;
    }

    .red {
      background: url("../../assets/icon_1.png");
      background-size: 100% 100%;
    }
    .red1 {
      background: url("../../assets/icon_2.png");
      background-size: 100% 100%;
    }
    .red2 {
      background: url("../../assets/icon_4.png");
      background-size: 100% 100%;
    }
    .red3 {
      background: url("../../assets/icon.png");
      background-size: 100% 100%;
    }
    h3 {
      flex: 1;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      text-align: left;
      line-height: 40px;
      span {
        color: #666;
        span {
          color: #4587ff;
          cursor: pointer;
        }
      }
    }
    .h3 {
      overflow: none;

      white-space: none;

      text-overflow: none;
    }
    .box {
      overflow: hidden;

      white-space: nowrap;

      text-overflow: ellipsis;
    }
    .clicklook {
      cursor: pointer;
      width: 100px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4587ff;
      margin-top: 13px;
    }
  }
}
.mt {
  margin-top: 10px;
}

.relation {
  width: 600px;
  height: 500px;
  background: #ffffff;
  box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.24);
  border-radius: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  padding: 10px;
  transform: translate(-50%, -50%);

  .re-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    span {
      height: 14px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 26px;
    }

    img {
      cursor: pointer;
    }
  }
}
.ocr-name-dialog {
  .el-dialog {
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    margin-top: 20vh !important;
    width: 340px;
    height: 200px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      padding: 10px 10px;
      .el-dialog__title {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
      .el-dialog__headerbtn {
        top: 12px;
        right: 11px;
      }
    }
    .el-dialog__body {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      flex: 1;
      padding: 20px 61px 35px 59px;
      .content {
        margin-top: 8px;
        @include flex-center;
        align-items: normal;
        flex: 1;
        font-size: 16px;
        color: #333333;
      }
      .footer {
        // @include flex-between;
        margin-top: 20px;
        text-align: center;
        @include noselect;
        > span {
          padding: 0 8px;
          font-size: 14px;
          font-weight: 500;
          color: #4587ff;
          cursor: pointer;
        }
      }
    }
  }
}
.ocr {
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -25px;
  padding: 25px 0 0 0;
  background-color: #f9f9f9;

  .box {
    display: flex;
    width: 1338px;
    box-sizing: border-box;
    margin: 0 auto;
    .left {
      position: relative;
      width: 936px;
      margin-right: 20px;

      background-color: #fff;
    }
    .right {
      background-color: #fff;
      flex: 1;
    }
  }

  .commonFooter_box {
    margin-top: 20px;
    width: 100%;
  }

  #upload {
    display: none;
  }
  .non-border {
    .idiom-editor-wrap {
      padding-bottom: 12px;
      border: none;
    }
  }
  .progress {
    height: 3px;
    background-color: #4587ff;
    transition: all 0.1s;
  }
  .idiomsub-nav {
    @include flex-start;

    // margin-left: -10px;
    .switch,
    .history {
      @include flex-start;
      display: inline-flex;
      margin-left: 20px;
      cursor: pointer;
      .txt {
        font-size: 12px;
        font-weight: 500;
        color: #262e3e;
        margin-left: 5px;
      }
      .im {
        width: 16px;
        height: 16px;
      }
    }
    .idiom-select-notes {
      margin-left: 20px;
    }
  }
  &-recomlist {
    .idiom-sort-wrap {
      @include flex-between;
      .tip {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
    .idiom-recom-list {
      padding-right: 20px;
      &::-webkit-scrollbar-track {
        display: none;
      }
      &::-webkit-scrollbar {
        width: 3px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ff6900;
      }
      .idiom-recom-item {
        margin-top: 30px;
      }
    }
    .idiom-recom-list {
      overflow-y: scroll;
      height: calc(100vh - 323px);
    }
    .el-pagination {
      padding: 10px 10px 20px;
    }
  }
  &-querylist {
    .title {
      @include flex-between;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
      span:first-child:hover {
        color: #4587ff;
      }
      .blue {
        font-size: 14px;
        color: #4587ff;
      }
    }
    .wrap {
      height: calc(100vh - 261px);
      margin-top: 15px;
      padding-right: 20px;
      overflow-y: scroll;
      &::-webkit-scrollbar-track {
        display: none;
      }
      &::-webkit-scrollbar {
        width: 3px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ff6900;
      }
      .idiom-query-item:first-child ~ .idiom-query-item {
        margin-top: 30px;
      }
    }
  }
  .relation {
    .idiom-recom-lists {
      height: 350px;
      overflow-y: scroll;
      &::-webkit-scrollbar-track {
        display: none;
      }
      &::-webkit-scrollbar {
        width: 3px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ff6900;
      }
      .idiom-recom-item {
        margin-top: 30px;
      }
    }
  }
}
</style>
