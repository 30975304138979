<template>
  <div class="idiomsub-nav">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'IdiomSubNav',
}
</script>

<style lang="scss" scoped>
.idiomsub-nav {
  @include noselect;
  width: 100%;
  height: 42px;
  margin-top: 2px;
  line-height: 42px;
  background: #fbfcfd;
  box-sizing: border-box;
  box-shadow: 0 0 0px 2px rgba(122, 179, 179, 0.1);
  .dropDownList {
    @include flex-start;
    display: inline-flex;
    display: inline-block;
    margin-left: 20px;
    padding: 0;
    ::v-deep .el-dropdown-link {
      color: #262e3e;
    }
  }
}
</style>
