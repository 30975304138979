import request from "api/request";
// 获取文件列表
export function ocrUploadImage(data) {
  return request({
    method: "post",
    url: "/ocr/analysisFile",
    data,
  });
}
// 获取文件列表
export function ocrPdfToWord(data) {
  return request({
    method: "post",
    url: "/ocr/pdfToWord",
    data,
  });
}

// 获取OCR记录
export function getOcrRecord(data) {
  return request({
    method: "post",
    url: "/ocr/record",
    data,
  });
}

// 获取
export function findFileIsComplete(data) {
  return request({
    method: "post",
    url: "/ocr/check?recordId=" + data,
  });
}
