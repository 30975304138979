<template>
  <div class="record">
    <div class="table-list">
      <el-table :data="tableData" v-loading="loading">
        <el-table-column
          width="50"
          label="序号"
          type="index"
          align="center"
        ></el-table-column>
        <el-table-column prop="targetText" align="center" label="文件名">
          <template slot-scope="scope">
            {{ scope.row.fileName || "[]" }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="时间">
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <div
              :class="[
                'states',
                scope.row.status === 0
                  ? 'gray'
                  : scope.row.status === 1
                  ? 'green'
                  : 'red',
              ]"
            >
              {{
                scope.row.status === 0
                  ? "转换中"
                  : scope.row.status === 1
                  ? "转换完成"
                  : "转换失败"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <img
              v-if="scope.row.status === 1"
              :src="
                scope.row.type == 1
                  ? require('assets/images/view-kind.png')
                  : require('assets/images/view-kind.png')
              "
              @click="handleClick(scope.row)"
              style="vertical-align: middle; cursor: pointer"
              alt=""
            />
            <span
              v-if="scope.row.status === 1"
              style="color: #4587ff; cursor: pointer"
              @click="handleClick(scope.row)"
              >{{ scope.row.type == 1 ? "查看" : "下载" }}</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="currentChange"
        :current-page="page"
        :page-size="limit"
        :pager-count="5"
        layout="total, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getOcrRecord } from "api/ocr";
import { isHasFreeCount } from "api/common";
export default {
  name: "Record",
  data() {
    return {
      page: 1,
      limit: 10,
      tableData: [],
      loading: false,
      pageTotal: 0,
    };
  },
  created() {
    this.initList();
  },
  methods: {
    async initList() {
      const data = {
        page: this.page,
        limit: this.limit,
      };
      this.loading = true;
      const res = await getOcrRecord(data);
      this.tableData = res.data.data;
      this.pageTotal = res.data.total;
      this.loading = false;
      console.log(res);
    },
    currentChange(val) {
      this.page = val;
      this.initList();
    },
    async handleClick(item) {
      // window.ShowDownLoadTips();

      if (!item.isOpen) {
        const res = await isHasFreeCount({
          menuName: "截图OCR",
          ocrRecordId: item.id,
        });
        if (res.code == 415) {
          this.$store.commit("setBuyTipsInfo", res.data);
          this.$bus.$emit("buyTips");
          return;
        }
      }
      console.log(item);
      if (item.type == 1) {
        if (!item.content) {
          this.$message("暂无内容可查看");
          return;
        }
        this.$emit("getChildContext", item.content);
      } else if (item.type == 2) {
        const name = item.fileName.substring(0, item.fileName.lastIndexOf("."));
        window.open(item.parseUrl + "?attname=" + name + ".docx");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.record {
  height: calc(100vh - 95px);
  padding: 15px;
  box-sizing: border-box;
  position: relative;
  .table-list {
    height: 100%;
    ::v-deep .el-pagination {
      width: 95%;
      box-sizing: border-box;
      text-align: center;
      position: absolute;
      bottom: 0;
    }
    ::v-deep .el-table {
      .el-table__body-wrapper {
        .states {
          display: inline-block;
          // width: 60px;
          padding: 0 5px;
        }
        .gray {
          display: inline-block;
          background: url("~assets/images/rhombus-gray.png") no-repeat;
          background-size: 100% 100%;

          color: #999;
        }

        .green {
          // width: 100%;
          display: inline-block;
          // position: absolute;
          // height: 100%;
          background: url("~assets/images/rhombus-green.png") no-repeat;
          background-size: 100% 100%;
          color: #fff;
          font-size: 12px;
        }
        .red {
          // width: 100%;
          display: inline-block;
          // position: absolute;
          // height: 100%;
          background: url("~assets/images/rhombus-red.png") no-repeat;
          background-size: 100% 100%;
          color: #fff;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
