export const defineSteps = [
  {
    element: "#ocrCom",
    popover: {
      title: "截图OCR",
      description: "截图OCR\n",
      position: "right-center",
    },
  },
  {
    element: "#CAJocrUpload",
    popover: {
      title: "CAJ转文字",
      description: "CAJ转文字\n",
      position: "bottom-center",
    },
  },
  {
    element: "#IMGocrUpload",
    popover: {
      title: "图片转文字",
      description: "图片转文字\n",
      position: "bottom-center",
    },
  },
  {
    element: "#PDFocrUpload",
    popover: {
      title: "PDF转文字",
      description: "PDF转文字\n",
      position: "bottom-center",
    },
  },
  {
    element: "#PPTocrUpload",
    popover: {
      title: "PPT转文字",
      description: "PPT转文字\n",
      position: "bottom-center",
    },
  },

  {
    element: "#insetLeft",
    popover: {
      title: "插入左侧",
      description: "插入左侧\n",
      position: "right-center",
    },
  },
  {
    element: "#copy",
    popover: {
      title: "复制",
      description: "复制\n",
      position: "right-center",
    },
  },
];

export default defineSteps;
